
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Palanquin:wght@100;200;300;400;500;600;700&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow:ital,wght@0,400..700;1,400..700&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;



@layer utilities {
  .padding {
    @apply sm:px-16 px-8 sm:py-24 py-12;
  }

  .padding-x {
    @apply sm:px-16 px-8;
  }

  .padding-y {
    @apply sm:py-24 py-12;
  }

  .padding-l {
    @apply sm:pl-16 pl-8;
  }

  .padding-r {
    @apply sm:pr-16 pr-8;
  }

  .padding-t {
    @apply sm:pt-24 pt-12;
  }

  .padding-b {
    @apply sm:pb-24 pb-12;
  }

  .info-text {
    @apply font-montserrat text-slate-gray text-lg leading-7;
    
  }

  .gradient-text {
    background-image: linear-gradient(to top, #3b4374, #ffffff);
    -webkit-background-clip: text;
    color: transparent;
  }

  .glow {
    color: #fff; /* Base color of the text */
    box-shadow: 0 0px 5px #e5b4ed;
  }
  .glow-project {
    color: #fff; /* Base color of the text */
    box-shadow: 0 0px 5px #1b28b4;
  }

  
  @keyframes bounce-once {
    0%, 100% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }

  .ripple {
    position: absolute;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.6); /* Adjust color and opacity */
    transform: scale(0);
    animation: ripple 0.6s linear;
    pointer-events: none; /* Prevent mouse interaction */
  }
  
  @keyframes ripple {
    to {
      transform: scale(4); /* Control the size of the ripple */
      opacity: 0;
    }
  }

  .hover-border {
    position: relative;
    overflow: hidden;
  }
  .hover-border::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #5b7edd;
    transition: width 0.4s;
  }
  .hover-border:hover::before {
    width: 100%;
  }

  .hover-3d {
    perspective: 1000px;
  }
  .hover-3d:hover img {
    transform: rotateY(10deg) rotateX(10deg);
    transition: transform 0.5s ease;
  }
 
  .hover-tilt:hover {
    transform: rotateX(5deg) rotateY(5deg);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .btn {
    position: relative;
    color: #625d5d;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .btn::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background: #445394; /* Change to desired color */
    transform: scaleX(0);
    transition: transform 0.3s;
  }
  
  .btn:hover {
    color: #d4a2d4; /* Change text color on hover */
  }
  
  .btn:hover::after {
    transform: scaleX(1);
  }
  
  
  

}